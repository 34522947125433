<template>
  <div
    @click="handleFocusOut($event)"
    :class="!cliente ? 'admin-app-container' : 'admin-app-container-modal'"
  >
    <Loading v-if="loading" />
    <Header v-if="!cliente" />
    <div class="pt-1 pa-sm-6 app-container">
      <h2 class="pl-2 pr-2 pa-sm-2 text-center">
        {{
          !cliente ? "Aprovação de Clientes" : "Indicações - " + cliente.nome
        }}
      </h2>
      <Grid
        :class="!cliente ? 'grid-component' : 'grid-component-modal'"
        ref="gridComponent"
        :fields="fields"
        :list="list"
        :filters="filters"
        :defaultInsertObject="defaultInsertObject"
        gridType="responsive"
        filterType="search"
        gridOverflow="vertical"
        :gridResizable="false"
        @listarItens="listarItens($event)"
        @selecionarItem="selecionarItem($event)"
        @exportarExcel="exportarExcel($event)"
        @salvarItem="salvarItem()"
        :minimizedItemList="[]"
        gridTableId="grid-admin"
        :hideInsert="true"
      />
      <Paginacao
        id="paginacao"
        :totalPaginas="totalPages"
        :paginaAtual="filters.pagina"
        :totalItens="totalItems"
        @alterarItensPorPagina="alterarItensPorPagina($event)"
        @alterarPagina="alterarPagina($event)"
      />
      <ModalSucesso
        :mensagem="mensagemAlerta"
        :alertaSucesso="alertaSucesso"
        :titulo="mensagemTitulo"
        @ocultarSucesso="ocultarSucesso"
      />
      <MensagemErro
        :mensagemAlerta="mensagemAlerta"
        :alertaErro="alertaErro"
        @ocultarErro="alertaErro = false"
        :login="false"
        :timeout="3000"
      />
      <v-row justify="center" v-if="item">
        <v-dialog
          v-model="modalAdmin"
          :fullscreen="windowWidth <= 600"
          max-width="1200px"
        >
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>Aprovação de Cliente</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="closeModalAdmin">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <Loading v-if="innerLoading" />
              <v-form ref="formAdmin" class="pt-4">
                <v-row class="pt-2 pb-2">
                  <v-col cols="12" xs="12" class="pa-0 pa-sm-1">
                    <Thumb
                      :item="item"
                      @selecionarImagem="selecionarImagem($event)"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="6" md="4" class="pa-0 pa-sm-1">
                    <DatePicker
                      @selecionarData="selecionarData($event)"
                      :obrigatorio="true"
                      model="data"
                      :retornarHorario="false"
                      :horarioInicial="true"
                      label="Data da Indicação"
                      :valorInicial="item.data"
                      :ocultarDetalhes="false"
                      refName="refData"
                      ref="refData"
                      :disabled="true"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="6" md="4" class="pa-0 pa-sm-1">
                    <v-autocomplete
                      ref="refQuemIndicou"
                      outlined
                      v-model="item.clienteIndicacaoId"
                      :items="usuarios"
                      item-text="nome"
                      item-value="clienteId"
                      label="Quem Indicou"
                      no-data-text="Nenhum cliente encontrado"
                      clearable
                      dense
                      :rules="[
                        (v) => !!v || 'O campo quem indicou é obrigatório',
                      ]"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" xs="12" sm="6" md="4" class="pa-0 pa-sm-1">
                    <v-text-field
                      ref="refNome"
                      label="Nome"
                      dense
                      v-model="item.nome"
                      clearable
                      outlined
                      :rules="[(v) => !!v || 'O campo nome é obrigatório']"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="6" md="4" class="pa-0 pa-sm-1">
                    <v-text-field
                      ref="refTelefone"
                      label="Telefone"
                      dense
                      v-model="item.telefone"
                      clearable
                      outlined
                      v-mask="['+## #####-######']"
                      :rules="[
                        (v) => !!v || 'O campo quem telefone é obrigatório',
                      ]"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="6" md="4" class="pa-0 pa-sm-1">
                    <v-text-field
                      ref="refEmail"
                      label="E-mail"
                      dense
                      v-model="item.email"
                      clearable
                      outlined
                      :rules="[(v) => !!v || 'O campo e-mail é obrigatório']"
                      :error-messages="item.emailDuplicado"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="6" md="4" class="pa-0 pa-sm-1">
                    <v-text-field
                      ref="refInstagram"
                      label="Instagram"
                      dense
                      v-model="item.instagram"
                      clearable
                      outlined
                      :rules="[(v) => !!v || 'O campo instagram é obrigatório']"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="pt-0" v-if="!cliente || item.clienteStatusId == $globals.clienteStatusPendente">
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                right
                class="text-caption"
                @click="openModalAprovacao(false)"
              >
                <v-icon class="text-caption">mdi-close</v-icon>Reprovar
              </v-btn>
              <v-btn
                color="green"
                text
                class="text-caption"
                @click="openModalAprovacao(true)"
              >
                <v-icon class="text-caption">mdi-check</v-icon>Aprovar
              </v-btn>
              <v-btn color="blue" text class="text-caption" @click="salvarItem">
                <v-icon class="text-caption">mdi-content-save-outline</v-icon>
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center" v-if="item">
        <v-dialog v-model="modalAprovacao" max-width="600px">
          <v-card>
            <v-toolbar dark :color="aprovacao ? 'green' : 'red'">
              <v-toolbar-title
                >{{ aprovacao ? "Aprovação" : "Reprovação" }} de
                Cadastro</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-btn icon dark @click="closeModalAprovacao">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="pl-4 pr-4">
              <p class="title pt-2 black--text">
                Deseja realmente {{ aprovacao ? "aprovar" : "reprovar" }} o
                cadastro selecionado?
              </p>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                right
                class="text-caption"
                @click="closeModalAprovacao"
              >
                <v-icon class="text-caption">mdi-close</v-icon>Não
              </v-btn>
              <v-btn
                :color="aprovacao ? 'green' : 'red'"
                text
                class="text-caption"
                @click="confirmarAprovacao"
              >
                <v-icon class="text-caption">mdi-check</v-icon>Sim
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loading.vue";
import Header from "../../components/header.vue";
import Paginacao from "../../components/paginacao.vue";
import Grid from "../../components/grid.vue";
import AprovacaoClienteService from "../../service/aprovacao-cliente-service.js";
import ExcelService from "../../service/excel-service.js";
import ModalSucesso from "../../components/modal-sucesso.vue";
import MensagemErro from "../../components/mensagem-erro.vue";
import DatePicker from "../../components/date-picker.vue";
import Thumb from "../../components/thumb.vue";
import { mask } from "vue-the-mask";
import ImageService from "../../service/image-service.js";
import UsuarioService from "../../service/usuario-service.js";

const imageService = new ImageService();
const aprovacaoClienteService = new AprovacaoClienteService();
const excelService = new ExcelService();
const usuarioService = new UsuarioService();

export default {
  props: ["cliente"],
  directives: {
    mask,
  },
  components: {
    Loading,
    Header,
    Paginacao,
    Grid,
    ModalSucesso,
    MensagemErro,
    DatePicker,
    Thumb,
  },
  data() {
    return {
      mensagemTitulo: "",
      mensagemAlerta: "",
      alertaSucesso: false,
      alertaErro: false,
      loading: false,
      filters: !this.cliente
        ? aprovacaoClienteService.listarFiltros()
        : aprovacaoClienteService.listarFiltrosPorCliente(this.cliente.clienteId),
      modalAdmin: false,
      item: null,
      totalPages: 1,
      totalItems: 1,
      fields: [],
      list: [],
      defaultInsertObject: {
        id: null,
        quemIndicou: null,
        nome: null,
        email: null,
        telefone: null,
        dataFormatada: null,
      },
      windowWidth: window.innerWidth,
      aprovacao: null,
      modalAprovacao: false,
      innerLoading: false,
      loadingUsuario: false,
      usuarios: []
    };
  },
  methods: {
    async confirmarAprovacao() {
      if (this.$refs.formAdmin.validate()) {
        this.modalAdmin = false;
        this.modalAprovacao = false;
        this.loading = true;
        let result = await aprovacaoClienteService.atualizaStatus(
          this.item.clienteId, 
          this.aprovacao ? this.$globals.clienteStatusAprovado : this.$globals.clienteStatusReprovado,
          this.item
        );
        if (result?.statusCode === 200) {
          this.loading = false;
          this.mensagemAlerta = `Cliente ${this.aprovacao ? "aprovado" : "reprovado"} com sucesso`;
          this.mensagemTitulo = `${this.aprovacao ? "Aprovação":"Reprovação"} de Cliente`;
          this.alertaSucesso = true;
          this.aprovacao = null;
          this.modalAdmin = false;
          this.modalAprovacao = false;
        } else if (result?.statusCode === 409) {
          this.item.emailDuplicado = result?.data.mensagem;
          this.$refs.refEmail.focus();
          this.loading = false;
          this.innerLoading = false;
        } else {
          this.mensagemAlerta = result?.data.mensagem;
          this.alertaErro = true;
          this.loading = false;
          this.innerLoading = false;
        }
      } else {
        if (!this.item.data) this.$refs.refData.setFocus();
        else if (!this.item.clienteIndicacaoId) this.$refs.refQuemIndicou.focus();
        else if (!this.item.nome) this.$refs.refNome.focus();
        else if (!this.item.telefone) this.$refs.refTelefone.focus();
        else if (!this.item.email) this.$refs.refEmail.focus();
        else if (!this.item.instagram) this.$refs.refInstagram.focus();
      }
    },
    openModalAprovacao(aprovacao) {
      this.modalAdmin = false;
      this.modalAprovacao = true;
      this.aprovacao = aprovacao;
    },
    closeModalAprovacao() {
      this.aprovacao = null;
      this.modalAdmin = true;
      this.modalAprovacao = false;
    },
    selecionarImagem(callback) {
      if(callback.success){
        this.item.foto = callback.data.base64;
        this.item.nomeArquivo = callback.data.name;
        this.item.base64String = callback.data.base64;
      }
      else {
        this.mensagemAlerta = callback.data;
        this.alertaErro = true;
      }
    },
    selecionarData(event) {
      if (this.item) this.item[event.model] = event.data;
    },
    handleFocusOut(event) {
      this.$refs.gridComponent?.handleFocusOut(event);
    },
    async exportarExcel(filtros) {
      this.loading = true;

      let result = null;
      if (!this.cliente)
        result = await aprovacaoClienteService.exportarAprovacoesExcel(filtros);
      else
        result = await aprovacaoClienteService.exportarAprovacoesExcelPorCliente(filtros);

      if (result?.statusCode === 200) {
        excelService.downloadFile(result?.data, !this.cliente ? "Aprovacoes.xlsx" : "Indicacoes.xlsx");
        this.loading = false;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loading = false;
      }
    },
    async selecionarItem(item) {
      this.item = JSON.parse(JSON.stringify(item));
      const foto = await imageService.validateImage(this.item.nomeArquivo);
      this.item.foto = foto.status == 404 ? null : foto.url;
      this.modalAdmin = true;
    },
    async salvarItem() {
      if (this.$refs.formAdmin.validate()) {
        if(!this.cliente)
          this.loading = true;
        else
          this.innerLoading = true;

        let result = await aprovacaoClienteService.atualizar(this.item);
        if (result?.statusCode === 200) {
          this.mensagemAlerta = "O cadastro foi atualizado com sucesso";
          this.mensagemTitulo = "Aprovação de Cliente";
          this.alertaSucesso = true;
          this.closeModalAdmin();
          this.$refs.formAdmin.resetValidation();
        } else if (result?.statusCode === 409) {
          this.item.emailDuplicado = result?.data.mensagem;
          this.$refs.refEmail.focus();
          this.loading = false;
          this.innerLoading = false;
        } else {
          this.mensagemAlerta = result?.data.mensagem;
          this.alertaErro = true;
          this.loading = false;
          this.innerLoading = false;
        }
      } else {
        if (!this.item.data) this.$refs.refData.setFocus();
        else if (!this.item.clienteIndicacaoId) this.$refs.refQuemIndicou.focus();
        else if (!this.item.nome) this.$refs.refNome.focus();
        else if (!this.item.telefone) this.$refs.refTelefone.focus();
        else if (!this.item.email) this.$refs.refEmail.focus();
        else if (!this.item.instagram) this.$refs.refInstagram.focus();
      }
    },
    adicionarCuradoria(item) {
      item.motivos.push(null);
    },
    excluirCuradoria(item, index) {
      item.motivos.splice(index, 1);
    },
    alterarPagina(pagina) {
      this.filters.pagina = pagina;
      this.listarItens();
    },
    alterarItensPorPagina(itens) {
      this.filters.itensPagina = itens;
      this.filters.pagina = 1;
      this.listarItens();
    },
    async listarItens() {
      this.loading = true;
      let result = null;
      if(!this.cliente)
        result = await aprovacaoClienteService.listarAprovacoes(this.filters);
      else
        result = await aprovacaoClienteService.listarAprovacoesPorCliente(this.filters);

      if (result?.statusCode === 200) {
        this.list = result?.data.lista;
        this.totalPages = result?.data.paginas;
        this.totalItems = result?.data.totalItens;
        this.loading = false;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loading = false;
      }
    },
    async listarUsuariosAtivos() {
      this.loadingUsuario = true;
      let result = await usuarioService.listarUsuariosAtivos();
      if (result?.statusCode === 200) {
        this.usuarios = result?.data;
        this.loadingUsuario = false;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loadingUsuario = false;
      }
    },
    closeModalAdmin() {
      this.loading = false;
      this.modalAdmin = false;
      this.item = null;
      const appContainer = document.querySelectorAll(".app-container")[0];
      appContainer.click();
    },
    ocultarSucesso() {
      this.alertaSucesso = false;
      this.loading = false;
      this.listarItens();
    },
  },
  created: async function () {
    if(!this.cliente)
      this.fields = await aprovacaoClienteService.listarCampos();
    else
      this.fields = await aprovacaoClienteService.listarCamposPorCliente();
    
    this.listarItens();
    this.listarUsuariosAtivos();
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
};
</script>

<style scoped>
.grid-component {
  max-height: calc(100vh - 284px) !important;
}
.grid-component-modal {
  max-height: calc(100vh - 240px) !important;
}
.admin-app-container {
  height: 100vh;
}
.admin-app-container-modal {
  height: calc(100vh - 120px);
}
.img-thumb {
  border-radius: 100%;
  width: 200px;
  height: 200px;
  object-fit: cover;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.thumb-camera {
  position: absolute;
  bottom: 14px;
  right: 14px;
}
.thumb-help {
  position: absolute;
  bottom: 52px;
  right: -14px;
}
h2{
  white-space: nowrap;
}
@media (max-width: 600px) {
  .grid-component {
    max-height: calc(100vh - 244px) !important;
  }
  .grid-component-modal {
    max-height: calc(100vh - 200px) !important;
  }
}
</style>