<template>
  <div class="d-flex justify-center mb-2">
    <div style="position: relative">
      <img
        v-if="item.foto"
        :src="item.foto"
        alt="imagem da cliente"
        class="img-thumb"
      />
      <v-icon v-if="!item.foto" size="200">mdi-account-circle</v-icon>
      <v-btn
        class="thumb-camera"
        fab
        dark
        small
        color="grey darken-3"
        @click="modalSelecaoThumb = true"
      >
        <v-icon dark> mdi-camera-outline </v-icon>
      </v-btn>
      <input
        type="file"
        accept="image/*"
        class="d-none"
        ref="refImagem"
        @change="setImagem($event)"
      />
    </div>
    <v-row justify="center" v-if="modalSelecaoThumb" class="posicao-absoluta">
      <v-dialog v-model="modalSelecaoThumb" max-width="400px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Update Photo</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="modalSelecaoThumb = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pa-4">
            <p class="blue--text title">
              <v-icon color="blue" left>mdi-information-outline</v-icon>Use
              images with square proportions for a better experience
            </p>
            <v-btn
              color="primary"
              text
              class="fill-width"
              @click="selecionarImagem"
            >
              <v-icon left>mdi-cloud-upload-outline</v-icon>
              Upload Image
            </v-btn>
            <v-btn color="primary" text class="fill-width" @click="tirarFoto">
              <v-icon left>mdi-camera-outline</v-icon>
              Take a picture
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center" v-if="modalFoto" class="posicao-absoluta">
      <v-dialog
        v-model="modalFoto"
        max-width="500px"
        persistent
        :fullscreen="windowWidth < 600"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Take a picture</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeModalFoto">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pt-2 pl-4 pr-4 pb-0">
            <div
              v-if="isCameraOpen"
              v-show="!isLoading"
              :class="
                isShotPhoto
                  ? 'camera-box d-flex justify-center flash'
                  : 'camera-box d-flex justify-center'
              "
            >
              <div
                :class="isShotPhoto ? 'camera-shutter flash' : 'camera-shutter'"
              ></div>
              <video
                v-show="!isPhotoTaken"
                ref="camera"
                :width="getVideoWidth()"
                :height="getVideoHeight()"
                autoplay
              ></video>
              <canvas
                v-show="isPhotoTaken"
                id="photoTaken"
                ref="canvas"
                :width="getVideoWidth()"
                :height="getVideoHeight()"
              ></canvas>
            </div>
          </v-card-text>
          <v-card-actions class="d-flex flex-column">
            <v-btn
              color="primary"
              text
              class="fill-width"
              @click="takePhoto"
              :disabled="!isCameraOpen"
            >
              <v-icon left>{{
                !isPhotoTaken ? "mdi-camera-outline" : "mdi-reload"
              }}</v-icon>
              {{ !isPhotoTaken ? "Capture photo" : "Try again" }}
            </v-btn>
            <v-btn
              color="primary"
              text
              class="fill-width"
              @click="savePhoto"
              :disabled="!isPhotoTaken"
            >
              <v-icon left>mdi-content-save-outline</v-icon>
              Save Image
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <MensagemErro
      :mensagemAlerta="mensagemAlerta"
      :alertaErro="alertaErro"
      @ocultarErro="alertaErro = false"
      :login="false"
    />
  </div>
</template>
<script>
import ImageService from "../service/image-service.js";
import MensagemErro from "./mensagem-erro.vue";
const imageService = new ImageService();

export default {
  components: {
    MensagemErro,
  },
  props: ["item"],
  data() {
    return {
      modalSelecaoThumb: false,
      modalFoto: false,
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      mensagemAlerta: "",
      alertaErro: false,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      mobile: this.detectMob(),
      streamWidth: 0,
      streamHeight: 0,
    };
  },
  methods: {
    getVideoWidth() {
      if (this.mobile) return this.windowWidth;
      else return this.windowWidth >= 500 ? 450 : this.windowWidth - 44;
    },
    getVideoHeight() {
      if (this.mobile)
        return (this.streamHeight * this.windowWidth) / this.streamWidth;
      else
        return this.windowWidth >= 500 ? 337.5 : (this.windowWidth - 44) * 0.75;
    },
    async setImagem(event) {
      this.modalSelecaoThumb = false;
      imageService.getBase64String(event, (callback) => {
        this.$emit("selecionarImagem", callback);
        this.$forceUpdate();
      });
      this.$refs.refImagem.value = "";
    },
    selecionarImagem() {
      this.$refs.refImagem.click();
    },
    tirarFoto() {
      this.modalSelecaoThumb = false;
      this.modalFoto = true;
      this.toggleCamera();
    },

    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          const streamSettings = stream.getVideoTracks()[0].getSettings();
          if(this.mobile && !navigator.userAgentData){
            this.streamWidth = streamSettings.height;
            this.streamHeight = streamSettings.width;
          }
          else{
            this.streamWidth = streamSettings.width;
            this.streamHeight = streamSettings.height;
          }
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch(() => {
          this.isLoading = false;
          this.modalFoto = false;
          this.mensagemAlerta = "Error loading device camera";
          this.alertaErro = true;
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(
        this.$refs.camera,
        0,
        0,
        this.getVideoWidth(),
        this.getVideoHeight()
      );
    },
    savePhoto() {
      this.modalFoto = false;
      const imagemBase64 = document
        .getElementById("photoTaken")
        .toDataURL("image/jpeg");
      const callback = {
        success: true,
        data: {
          base64: imagemBase64,
          name: "captura_tela.jpg",
        },
      };
      this.$emit("selecionarImagem", callback);
      this.toggleCamera();
    },
    closeModalFoto() {
      this.modalFoto = false;
      this.toggleCamera();
    },
    detectMob() {
      if (navigator.userAgentData) return navigator.userAgentData.mobile;
      else {
        const toMatch = [
          /Android/i,
          /webOS/i,
          /iPhone/i,
          /iPad/i,
          /iPod/i,
          /BlackBerry/i,
          /Windows Phone/i,
        ];

        return toMatch.some((toMatchItem) => {
          return navigator.userAgent.match(toMatchItem);
        });
      }
    },
  },
  created: function () {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      this.mobile = this.detectMob();
    });
  },
};
</script>
<style scoped>
.img-thumb {
  border-radius: 100%;
  object-fit: cover;
  width: 200px;
  height: 200px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.thumb-camera {
  position: absolute;
  bottom: 14px;
  right: 14px;
}
.fill-width {
  width: 100%;
}
.camera-shutter {
  opacity: 0;
  max-height: 450px;
  background-color: #fff;
  position: absolute;
}
.flash {
  opacity: 1;
}
.posicao-absoluta {
  position: absolute;
}
.video-thumb {
  width: 100%;
  max-width: 450px;
  max-height: 450px;
}
</style>