<template>
  <div>
    <hr class="mb-2">
    <div class="container-paginacao">
      <div class="hidden-xs-only">
        <div class="d-flex align-center">
          Itens por página
          <v-select
            class="pagina-atual-select"
            v-model="itensPorPagina"
            :items="[10, 20, 50, 100]"
            :menu-props="{ maxHeight: '400', offsetY: true, top: false }"
            label="Itens por página"
            single-line
            dense
            flat
            solo
            hide-details
            @change="alterarItensPorPagina()"
          ></v-select>
        </div>
      </div>
      <div class="d-flex align-center justify-flex-end">
        <div class="ml-4 mr-4 d-flex align-center">
          <div>
            {{ totalItens == 0 ? 0 : !paginaAtual ? 0 : paginaAtual * itensPorPagina - itensPorPagina + 1 }} -
            {{
              paginaAtual * itensPorPagina > totalItens
                ? totalItens
                : paginaAtual * itensPorPagina
            }}
            <span class="hidden-sm-and-down"> de {{ totalItens }} itens |</span>
          </div>
          <div style="width: 115px;" class="hidden-sm-and-down">
            <v-select
              class="pa-0 ma-0"
              v-model="paginaAtual"
              :items="pAtuaisList"
              :menu-props="{ maxWidth: '100', maxHeight: '400', offsetOverflow: false, offsetY: true, top: true }"
              dense
              solo
              flat
              hide-details
              @change="alterarPagina"
              ref="refPaginaAtual"
            >
            </v-select>
          </div>
          <div class="pagina-atual-descricao ml-1 ml-md-0">
            de {{ totalPaginas == 1 ? totalPaginas + " página" : totalPaginas + " páginas"}}
          </div>
        </div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="primary"
              v-on="on"
              @click="primeiraPagina"
              class="btn-paginacao"
            >
              <v-icon>mdi-page-first</v-icon>
            </v-btn>
          </template>
          <span>Primeira página</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="primary"
              v-on="on"
              @click="paginaAnterior"
              class="btn-paginacao"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>Página anterior</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="primary"
              v-on="on"
              @click="proximaPagina"
              class="btn-paginacao"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </template>
          <span>Próxima página</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="primary"
              v-on="on"
              @click="ultimaPagina"
              class="btn-paginacao"
            >
              <v-icon>mdi-page-last</v-icon>
            </v-btn>
          </template>
          <span>Última página</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["totalPaginas", "paginaAtual", "totalItens"],
  name: "Paginacao",
  data() {
    return {
      itensPorPagina: 10,
      tPaginas: 1,
      pAtual: 1,
      buscaPaginaAtual: '',
    };
  },
  methods: {
    primeiraPagina() {
      this.paginaAtual = 1;
      this.$emit("alterarPagina", this.paginaAtual);
    },
    proximaPagina() {
      this.paginaAtual =
        this.paginaAtual < this.totalPaginas
          ? this.paginaAtual + 1
          : this.totalPaginas;
      this.$emit("alterarPagina", this.paginaAtual);
    },
    paginaAnterior() {
      this.paginaAtual =
        this.paginaAtual > 1 ? this.paginaAtual - 1 : this.paginaAtual;
      this.$emit("alterarPagina", this.paginaAtual);
    },
    ultimaPagina() {
      this.paginaAtual = this.totalPaginas;
      this.$emit("alterarPagina", this.paginaAtual);
    },
    alterarPagina() {
      this.$emit("alterarPagina", this.paginaAtual);
      this.$refs.refPaginaAtual.blur();
    },
    alterarItensPorPagina() {
      this.$emit("alterarItensPorPagina", this.itensPorPagina);
    },
  },
  watch: {
    totalPaginas(){
      if(this.totalPaginas < this.paginaAtual)
        this.primeiraPagina();
    }
  },
  computed: {
    pAtuaisList: function () {
      let list = [];
      for (let i = 1; i <= this.totalPaginas; i++) {
        list.push(i);
      }
      return list
    }
  }
};
</script>
<style scoped>
.btn-paginacao:hover {
  background-color: #e4effa;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.container-paginacao {
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pagina-atual-select {
  width: min-content;
}
.pagina-atual-descricao {
  z-index: 1;
}

@media (max-width: 600px) {
  .container-paginacao {
    justify-content: flex-end;
  }
}

</style>