var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('hr',{staticClass:"mb-2"}),_c('div',{staticClass:"container-paginacao"},[_c('div',{staticClass:"hidden-xs-only"},[_c('div',{staticClass:"d-flex align-center"},[_vm._v(" Itens por página "),_c('v-select',{staticClass:"pagina-atual-select",attrs:{"items":[10, 20, 50, 100],"menu-props":{ maxHeight: '400', offsetY: true, top: false },"label":"Itens por página","single-line":"","dense":"","flat":"","solo":"","hide-details":""},on:{"change":function($event){return _vm.alterarItensPorPagina()}},model:{value:(_vm.itensPorPagina),callback:function ($$v) {_vm.itensPorPagina=$$v},expression:"itensPorPagina"}})],1)]),_c('div',{staticClass:"d-flex align-center justify-flex-end"},[_c('div',{staticClass:"ml-4 mr-4 d-flex align-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.totalItens == 0 ? 0 : !_vm.paginaAtual ? 0 : _vm.paginaAtual * _vm.itensPorPagina - _vm.itensPorPagina + 1)+" - "+_vm._s(_vm.paginaAtual * _vm.itensPorPagina > _vm.totalItens ? _vm.totalItens : _vm.paginaAtual * _vm.itensPorPagina)+" "),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v(" de "+_vm._s(_vm.totalItens)+" itens |")])]),_c('div',{staticClass:"hidden-sm-and-down",staticStyle:{"width":"115px"}},[_c('v-select',{ref:"refPaginaAtual",staticClass:"pa-0 ma-0",attrs:{"items":_vm.pAtuaisList,"menu-props":{ maxWidth: '100', maxHeight: '400', offsetOverflow: false, offsetY: true, top: true },"dense":"","solo":"","flat":"","hide-details":""},on:{"change":_vm.alterarPagina},model:{value:(_vm.paginaAtual),callback:function ($$v) {_vm.paginaAtual=$$v},expression:"paginaAtual"}})],1),_c('div',{staticClass:"pagina-atual-descricao ml-1 ml-md-0"},[_vm._v(" de "+_vm._s(_vm.totalPaginas == 1 ? _vm.totalPaginas + " página" : _vm.totalPaginas + " páginas")+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btn-paginacao",attrs:{"icon":"","color":"primary"},on:{"click":_vm.primeiraPagina}},on),[_c('v-icon',[_vm._v("mdi-page-first")])],1)]}}])},[_c('span',[_vm._v("Primeira página")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btn-paginacao",attrs:{"icon":"","color":"primary"},on:{"click":_vm.paginaAnterior}},on),[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)]}}])},[_c('span',[_vm._v("Página anterior")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btn-paginacao",attrs:{"icon":"","color":"primary"},on:{"click":_vm.proximaPagina}},on),[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}}])},[_c('span',[_vm._v("Próxima página")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btn-paginacao",attrs:{"icon":"","color":"primary"},on:{"click":_vm.ultimaPagina}},on),[_c('v-icon',[_vm._v("mdi-page-last")])],1)]}}])},[_c('span',[_vm._v("Última página")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }