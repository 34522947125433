import Vue from "vue";
const vue = new Vue();

class ImageService {
    getBase64String(event, callback) {
        const elemento = event.srcElement;
        if (elemento.files.length > 0) {
            const arquivo = elemento.files[0];
            if ((arquivo.type.indexOf("image/png") != -1 || 
                arquivo.type.indexOf("image/jpeg") != -1 ||
                arquivo.type.indexOf("image/gif") != -1) &&
                arquivo.size > 0) {
                var reader = new FileReader();
                reader.readAsBinaryString(arquivo);

                reader.onload = () => {
                    callback({
                        success: true,
                        data: { 
                            base64: `data:${arquivo.type};base64, ${window.btoa(
                                reader.result)}`,
                            name: arquivo.name
                        }
                    });
                };
            }
            else {
                callback({
                    success: false,
                    data: "Selecione uma imagem PNG, JPEG ou GIF"
                });
            }
        }
    }

    async validateImage(file){
        let requestOptions = {
            method: 'GET',
            headers: vue.$globals.headerPadrao
        };
        const fetchResponse = await fetch(`${vue.$globals.endpoint}imagem/getStream?nomeArquivo=${file}`, requestOptions);
        return await fetchResponse;
    }
}

export default ImageService;